import React from 'react';
import DefaultLayout from '../DefaultLayout';
import HeroLinks from '../../components/HeroLinks';

const links = [
	{ url: '/education-hub/', label: 'All' },
	{ url: '/webinars/', label: 'Webinars' },
	{ url: '/events/', label: 'Events' },
	{ url: '/videos/', label: 'Videos' },
	{ url: '/ebooks-articles/', label: 'eBooks & Articles' },
	{ url: '/resources/', label: 'Resources' },
];

const EducationHubLayout = ({ children, location, data }) => {
	const heroInfo = data.page?.educationHubPageDetails?.educationHubHero;
	return (
		<DefaultLayout location={location}>
			<HeroLinks links={links} {...heroInfo} />
			{children}
		</DefaultLayout>
	);
};

export default EducationHubLayout;
